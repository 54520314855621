import {Component, Inject} from "@angular/core";
import {
    BoardGameBasicResponse,
    GamePlayState,
    GamePlayTemplateResponse, LocationResponse, PlannedPlayResponse
} from "../model/responses";
import {GamePlayService} from "../service/api/game-play.service";
import {UserGameService} from "../service/api/user-game.service";
import {GamePlayRequest} from "../model/requests";
import {MatSnackBar} from "@angular/material/snack-bar";
import {GamePlayData, LogPlayDataFormComponent} from "./log-play-data-form.component";
import {BreakpointObserver} from "@angular/cdk/layout";
import {LoggedPlayPlayer, PlayersSelectionFormComponent} from "./players-selection-form.component";
import {MatDialog, MatDialogConfig, MatDialogRef, MatDialogModule, MAT_DIALOG_DATA} from "@angular/material/dialog";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {MatSlideToggleModule} from "@angular/material/slide-toggle";
import {WritePostPanelComponent} from "../post/write-post-panel.component";
import {MatDividerModule} from "@angular/material/divider";
import {MatButtonModule} from "@angular/material/button";
import {MatIconModule} from "@angular/material/icon";
import {MatButtonToggleModule} from "@angular/material/button-toggle";
import {GameSearchFormComponent} from "../game/game-search-form.component";
import {CommonModule} from "@angular/common";
import {CurrentViewDataService} from "../service/current-view-data.service";
import {ChooseRandomGameDialogComponent} from "../game/choose-random-game-dialog.component";
import {parseDurationToSeconds} from "./edit-duration-dialog.component";
import {DialogService} from "../service/ui/dialog.service";
import {BoardGameService} from "../service/api/board-game.service";
import {UserGameOwnership} from "../model/user-game-ownership";
import {dateToString} from "../model/period";
import {AuthedUserService} from "../service/authed-user.service";
import {MatStepperModule} from "@angular/material/stepper";
import {AvatarComponent} from "../user/avatar.component";
import {SharedDialogsService} from "../service/shared-dialogs.service";
import {GamePlayTemplateService} from "../service/api/game-play-template.service";
import {LocationService} from "../service/api/location.service";
import {Visibility} from "../model/visibility";

@Component({
    template: `
        <h1 mat-dialog-title i18n>New Play</h1>
        <mat-dialog-content>
            <div class="log-play-form column">
                <cb-game-search-form
                    cdkFocusInitial
                    [selectedGame]="selectedGame" [error]="gameError"
                    onlyBaseGames chooseExpansions
                    [preloadedGames]="preloadedGames || []"
                    i18n-extraOptionText extraOptionText="Help me choose a game"
                    extraOptionIcon="quiz"
                    (selectedGameChange)="selectGame($event)"
                    [(selectedExpansions)]="selectedExpansions"
                    (extraOptionClick)="chooseRandomGame()"
                ></cb-game-search-form>
            </div>
            <div *ngIf="mode == 'play'">
                <ng-template matStepLabel i18n>Details</ng-template>
                <div class="row center align-center" style="margin-bottom: 8px">
                    <button mat-stroked-button style="width: 100%" *ngIf="playData?.location" (click)="openLocationsDialog()">
                        <mat-icon>location_on</mat-icon>
                        <span>{{ playData?.location?.name }}</span>
                    </button>
                    <button mat-icon-button class="small-icon-button" *ngIf="playData?.location" (click)="clearLocation()">
                        <mat-icon>close</mat-icon>
                    </button>
                    <button mat-stroked-button style="width: 100%" *ngIf="!playData?.location" (click)="openLocationsDialog()">
                        <mat-icon>not_listed_location</mat-icon>
                        <span i18n>Set Play Location</span>
                    </button>
                </div>
                <div class="form-heading" i18n>Players</div>
                <div *ngFor="let player of displayedPlayers" class="row space-between align-center" style="margin-bottom: 8px">
                    <div class="row left align-center">
                        <cb-avatar [name]="player.name" [avatarUrl]="player.avatarUrl" [size]="34"></cb-avatar>
                        <div class="player-text"
                             [style.max-width]="'calc(100vw - ' + (64 + 48 + 48 + 32 + (player.score ? 30 : 0)) + 'px)'">
                            <span class="team-name" *ngIf="player.players" i18n>[TEAM]</span>
                            <span class="player-name">{{ player.name }}</span>
                            <span class="player-username" *ngIf="player.username">&#64;{{ player.username }}</span>
                            <!--<span class="player-username" *ngIf="!player.username" i18n>(Non-user player)</span>-->
                            <!--<span class="player-username" *ngIf="!player.username" i18n>(Non-user player)</span>-->
                        </div>
                    </div>
                    <button *ngIf="player.userPlayerId" mat-icon-button class="small-icon-button" style="color: white" (click)="editUserPlayer(player)">
                        <mat-icon>edit</mat-icon>
                    </button>
                </div>
                <button mat-raised-button color="primary" class="select-players-button" (click)="selectPlayers()">
                    <mat-icon>group</mat-icon>
                    <span i18n>Select players</span>
                </button>
            </div>
            <cb-log-play-data-form *ngIf="mode == 'log'" [game]="selectedGame" [players]="players" [playData]="playData"
                                   (playDataChange)="playData = $event; selectedTemplate = $event.template; players = $event.players"></cb-log-play-data-form>

            <cb-write-post-panel *ngIf="mode == 'log'"
                                [(postContent)]="postContent"
                                [(files)]="files"
            ></cb-write-post-panel>
            <div class="saving column center align-center" *ngIf="saving">
                <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
            </div>
        </mat-dialog-content>
        <div mat-dialog-actions>
            <div class="row fillWidth space-between">
                <button *ngIf="selectedGame" mat-button cdkFocusInitial (click)="cancel()" i18n>Cancel</button>
                <button *ngIf="!selectedGame" mat-button (click)="cancel()" i18n>Cancel</button>
                <button *ngIf="mode == 'log'" mat-raised-button color="primary" (click)="save(true)"
                        [disabled]="!selectedGame || gameError != undefined || !playData || players.length == 0 || saving"
                        i18n>Log</button>
                <button *ngIf="mode == 'play'" mat-raised-button color="primary" (click)="save(false)"
                        [disabled]="!selectedGame || gameError != undefined || !playData || players.length == 0 || saving"
                        i18n>Start</button>
            </div>
        </div>
    `,
    styles: [`
        .section-separation {
            margin-bottom: 16px;
        }

        .log-play-form {

            .mat-form-field {
                width: 100%;
            }

            .log-full-width {
                width: 100%;
            }

            .add-player-field {
                margin-top: 10px;
            }
        }

        .form-heading {
            margin-top: 16px;
            margin-bottom: 8px;
            font-size: 1.1rem;
            font-weight: 700;
        }

        .panel-heading {
            margin-left: 8px;
            font-size: 1rem;
            font-weight: 400;
        }

        .optional-heading {
            margin-left: 8px;
            font-size: 1rem;
            font-weight: 400;
            color: #b1b8be;
        }

        .winner-indicator {
            width: 30px;
        }

        .player-text {
            margin-left: 8px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            color: white;

            .player-name {
                font-size: 1rem;
                font-weight: 700;
            }

            .player-username {
                margin-left: 6px;
                font-size: 1rem;
                font-weight: 400;
                color: #b1b8be;
                min-width: 70px;
            }

            .team-name {
                margin-right: 6px;
                font-size: 0.938rem;
                font-weight: 400;
                color: #b1b8be;
                min-width: 70px;
            }
        }

        .player-score {
            font-size: 1rem;
            font-weight: 700;
            text-decoration: underline;
            text-align: right;
            margin-right: 8px;
        }

        .select-players-button {
            margin-top: 8px;
            width: 100%;
        }

        .saving {
            margin-top: 32px;
        }

        .write-post-attachment-buttons {
            margin-top: 10px;
            margin-right: 10px;
        }

        .mat-mdc-dialog-content {
            max-height: unset !important;
        }
    `],
    standalone: true,
    imports: [
        CommonModule,
        MatDialogModule, MatButtonToggleModule, MatIconModule, MatButtonModule, MatDividerModule, MatSlideToggleModule, MatProgressSpinnerModule, MatStepperModule,
        GameSearchFormComponent, AvatarComponent, WritePostPanelComponent, LogPlayDataFormComponent,
    ]
})
export class CreatePlayDialogComponent {

    mode = localStorage.getItem('playMode') || 'play'
    rememberMode = localStorage.getItem('rememberPlayMode') == 'true'

    stepperPosition = 0//this.rememberMode ? 1 : 0

    selectedGame?: BoardGameBasicResponse
    targetOwnership?: UserGameOwnership

    gameError?: string

    postContent = ''
    files: Blob[] = []

    saving = false

    preloadedGames?: BoardGameBasicResponse[]
    selectedExpansions: BoardGameBasicResponse[] = []

    players: LoggedPlayPlayer[] = []
    displayedPlayers: LoggedPlayPlayer[] = []
    playData?: GamePlayData

    selectedTemplate?: GamePlayTemplateResponse

    locations: LocationResponse[] = []
    defaultLocation?: LocationResponse

    constructor(
        userGameService: UserGameService,
        @Inject(MAT_DIALOG_DATA) public data: { mode?: 'play' | 'log', plannedPlay?: PlannedPlayResponse},
        private dialogRef: MatDialogRef<CreatePlayDialogComponent>,
        private boardGameService: BoardGameService,
        private gamePlayService: GamePlayService,
        private gamePlayTemplateService: GamePlayTemplateService,
        private snackBar: MatSnackBar,
        private dialog: MatDialog,
        public breakpointObserver: BreakpointObserver,
        private currentViewDataService: CurrentViewDataService,
        private dialogService: DialogService,
        private authedUserService: AuthedUserService,
        private sharedDialogsService: SharedDialogsService,
        private locationService: LocationService,
    ) {
        this.authedUserService.assertAuthedUser()
        const user = this.authedUserService.getUserData()!
        this.selectGame(this.currentViewDataService.selectedGame)
        if (data.mode) {
            this.mode = data.mode
        }
        //if (this.rememberMode && this.currentViewDataService.selectedGame) {
        //    this.stepperPosition = 2
        //}
        const selectedPlay = this.currentViewDataService.selectedPlay
        if (data.plannedPlay) {
            this.players = []
            for (let participant of data.plannedPlay.participants) {
                this.players.push({
                    name: participant.user.name,
                    userId: participant.user.id,
                    username: participant.user.username,
                    avatarUrl: participant.user.avatarUrl,
                })
                for (let userPlayer of participant.userPlayers) {
                    this.players.push({
                        name: userPlayer.name,
                        userPlayerId: userPlayer.id,
                        avatarUrl: userPlayer.avatarUrl,
                    })
                }
            }
            this.playData = {
                date: data.plannedPlay.startsAt ? new Date(data.plannedPlay.startsAt) : new Date(),
                location: data.plannedPlay.location,
                players: this.players,
            }
        } else if (selectedPlay) {
            this.selectedExpansions = selectedPlay.expansions
            if (selectedPlay.access) {
                this.players = selectedPlay.players.map(p => {
                    return {
                        name: p.name,
                        userId: p.userId,
                        userPlayerId: p.userPlayerId,
                        username: p.username,
                        avatarUrl: p.avatarUrl
                    }
                })
                if (selectedPlay.template) {
                    this.selectedTemplate = selectedPlay.template
                }
            }
        }
        if (this.players.length == 0) {
            this.gamePlayService.getUserGamePlays(this.authedUserService.getUserData()!.username, [GamePlayState.FINISHED], 1).subscribe(response => {
                if (response.elements.length > 0 && dateToString(response.elements[0].date) == dateToString(new Date())) {
                    this.players = response.elements[0].players.map(p => {
                        return {
                            name: p.name,
                            userId: p.userId,
                            userPlayerId: p.userPlayerId,
                            username: p.username,
                            avatarUrl: p.avatarUrl
                        }
                    })
                }
                if (!this.players.some(p => p.userId == user.id)) {
                    const userPlayer = {name: user.name, userId: user.id, username: user.username, avatarUrl: user.avatarUrl}
                    this.players = [userPlayer]
                }
                this.updateDisplayedPlayers()
                this.updateData()
            })
        }
        userGameService.getLastPlayedGames().subscribe(response => {
            this.preloadedGames = response.list
        })
        this.locationService.getLocations().subscribe(locations => {
            this.locations = locations.list
            this.defaultLocation = locations.list.find(l => l.isDefault)
            if (this.playData && !this.playData.location) {
                this.playData!.location = this.defaultLocation
            }
        })
        this.updateDisplayedPlayers()
        this.updateData()
    }

    saveMode() {
        localStorage.setItem('playMode', this.mode)
        localStorage.setItem('rememberPlayMode', this.rememberMode ? 'true' : 'false')
    }

    chooseRandomGame() {
        let dialogConfig: MatDialogConfig = {
            width: '350px',
            height: '600px',
            data: {
                players: this.players,
            }
        }
        this.dialogService.fullScreenIfMobile(dialogConfig)
        const dialog = this.dialog.open(ChooseRandomGameDialogComponent, dialogConfig)
        this.dialogService.onDialogOpened(dialog)
        dialog.afterClosed().subscribe(game => {
            this.selectedGame = game
            this.selectedExpansions = []
        })
    }

    selectGame(game?: BoardGameBasicResponse) {
        this.selectedTemplate = undefined
        this.selectedExpansions = []
        if (!game) {
            this.selectedGame = undefined;
            return;
        }

        setTimeout(() => {
            if (!this.selectedGame) {
                this.selectedGame = game
            }
        }, 500)
        this.boardGameService.getGameExpansions(game.slug).subscribe(expansions => {
            if (expansions.bases.length > 0) {
                this.selectedGame = expansions.bases[0];
                this.selectedExpansions = [game]
            } else {
                this.selectedGame = game
            }
        })

        const defaultTemplate = localStorage.getItem(`defaultTemplate-${game.id}`)
        if (defaultTemplate) {
            this.gamePlayTemplateService.getGamePlayTemplate(defaultTemplate).subscribe(template => {
                this.selectedTemplate = template
            })
        }
    }

    cancel() {
        let cancel = true
        console.log(this.selectedExpansions)
        console.log(this.playData)
        console.log(this.postContent)
        console.log(this.playData?.location)

        if (
            this.selectedExpansions.length > 0 ||
            (this.selectedGame?.slug != this.currentViewDataService.selectedGame?.slug) ||
            ((this.playData && (this.playData.players.length > 1 || this.playData.duration || this.playData.notes)) ||
                this.postContent.length > 0 || this.files.length > 0)
        ) {
            cancel = window.confirm($localize`Are you sure you want to cancel? All unsaved changes will be lost.`)
        }
        if (cancel) {
            this.dialogRef.close()
        }
    }

    updateDisplayedPlayers() {
        const teamNames = this.players.filter(p => p.team)
            .map(p => p.team!)
            .filter((v, i, a) => a.indexOf(v) === i)
        const teams: LoggedPlayPlayer[] = teamNames.map(t => {
            const players = this.players.filter(p => p.team == t)
            const player = players[0]
            return {
                name: t,
                isWinner: player.isWinner,
                score: player.score,
                scoreBreakdown: player.scoreBreakdown,
                players: players,
            }
        })
        this.displayedPlayers = this.players.filter(p => !p.team)
        this.displayedPlayers.push(...teams)
    }

    updateData() {
        this.playData = {
            ...this.playData!,
            players: this.players,
            template: this.selectedTemplate || this.playData?.template
        }
    }

    save(finished: boolean) {
        if (!this.selectedGame) {
            this.gameError = $localize`Please select a game`
            return
        }

        if (this.gameError || !this.playData || this.players.length == 0) {
            return
        }

        const durationSeconds = this.playData?.duration ? parseDurationToSeconds(this.playData.duration) : undefined
        const notes = this.playData?.notes
        const request: GamePlayRequest = {
            playId: this.playData?.playId,
            gameId: this.selectedGame.id,
            expansionIds: this.selectedExpansions.map(e => e.id),
            players: this.players.map(p => {
                const score = p.score && !isNaN(parseInt(p.score)) ? parseInt(p.score) : undefined
                return {
                    name: p.name,
                    userId: p.userId,
                    userPlayerId: p.userPlayerId,
                    isWinner: p.isWinner,
                    score: score,
                    scoreBreakdown: p.scoreBreakdown,
                    color: p.color,
                    team: p.team,
                    isBot: p.isBot,
                    inviteCode: p.inviteCode
                }
            }),
            state: finished ? GamePlayState.FINISHED : GamePlayState.PREPARING,
            date: dateToString(this.playData?.date || new Date()),
            count: this.playData?.count || 1,
            durationSeconds: durationSeconds,
            locationId: this.playData.location?.id,
            notes: notes && notes.length > 0 ? notes : undefined,
            postContent: this.postContent.length > 0 ? this.postContent : undefined,
            templateId: this.selectedTemplate?.id
        }

        this.saving = true
        this.gamePlayService.createGamePlay(request, this.files).subscribe({
            next: response => {
                this.saving = false
                this.dialogRef.close(response)
            },
            error: error => {
                this.saving = false
                this.snackBar.open(error.error.message || $localize`There was an error fulfilling your request. Please try again later`, 'OK', {duration: 5000})
            }
        })
    }

    openLocationsDialog() {
        this.sharedDialogsService.openLocations(true, Visibility.PRIVATE).subscribe(location => {
            if (location) {
                this.playData!.location = location
                this.updateData()
            }
        })
    }

    clearLocation() {
        this.playData!.location = undefined
        this.updateData()
    }

    selectPlayers() {
        this.sharedDialogsService.selectPlayers(this.players).subscribe(players => {
            if (players) {
                this.players = players
                this.updateDisplayedPlayers()
                this.updateData()
            }
        })
    }

    editUserPlayer(player: LoggedPlayPlayer) {
        if (!player.userPlayerId) {
            return
        }
        this.sharedDialogsService.editUserPlayer(player).subscribe(result => {
            if (result) {
                this.players = this.players.map(p =>
                    p.userPlayerId == result.id ? {...p, ...result} : p
                )
                this.updateDisplayedPlayers()
                this.updateData()
            }
        })
    }
}
