import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {Visibility} from "../model/visibility";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatOptionModule } from "@angular/material/core";
import { NgFor } from "@angular/common";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { MatSelectModule } from "@angular/material/select";
import { MatFormFieldModule } from "@angular/material/form-field";

@Component({
    selector: 'cb-location-visibility-select',
    template: `
    <mat-form-field appearance="fill" class="fill">
      <mat-label i18n>Visibility</mat-label>
      <mat-select [(ngModel)]="visibility" (ngModelChange)="visibilityChange.emit(visibility)" name="visibility">
        <mat-option *ngFor="let visibility of visibilities" [value]="visibility"
                    [matTooltip]="getVisibilityText(visibility).description">
          <span>{{getVisibilityText(visibility).name}}</span>
        </mat-option>
      </mat-select>
      <button mat-icon-button matSuffix
              [matTooltip]="getVisibilityText(visibility).description"
              (click)="visibilityTooltip.toggle(); $event.stopPropagation()" #visibilityTooltip="matTooltip">
        <mat-icon>help_outline</mat-icon>
      </button>
    </mat-form-field>
  `,
    styles: [`
  `],
    standalone: true,
    imports: [MatFormFieldModule, MatSelectModule, ReactiveFormsModule, FormsModule, NgFor, MatOptionModule, MatTooltipModule, MatButtonModule, MatIconModule]
})
export class LocationVisibilitySelectComponent {

  visibilities = [Visibility.PUBLIC, Visibility.PROTECTED, Visibility.PRIVATE]

  @Input()
  visibility: Visibility

  @Output()
  visibilityChange = new EventEmitter<Visibility>()

  getVisibilityText(visibility: Visibility): {name: string, description: string} {
    return this.locationVisibilityTexts[visibility]
  }

  locationVisibilityTexts = locationVisibilityTexts
}

export const locationVisibilityTexts = {
    [Visibility.PUBLIC]: {
        name: $localize`Public`,
        description: $localize`The address and map location will be visible to everyone.`,
        icon: 'lock_open',
    },
    [Visibility.PROTECTED]: {
        name: $localize`Protected`,
        description: $localize`Only your friends will be able to see the address and map location.`,
        icon: 'lock',
    },
    [Visibility.PRIVATE]: {
        name: $localize`Private`,
        description: $localize`Only you will be able to see the address and map location.`,
        icon: 'lock',
    }
}
